import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { of } from "rxjs";
import { environment } from "src/environments/environment";
import { EventService } from "./event.service";
import { PropertyService } from "./property.service";
import { PropertyModel } from "../models/property.model";

@Injectable({
  providedIn: 'root'
})
export class SavedPropertiesService {
  private savedProperties: any[] = [];
  private propertiesData: any[] = [];

  constructor(private dbService: NgxIndexedDBService, private propertyService: PropertyService, private eventService: EventService) { }

  initSavedService() {
    this.dbService.getAll('saved-properties').subscribe({
      next: properties => {
        this.savedProperties = [];
        properties.map((property: any) => {
          this.savedProperties.push(property.uniqueId);
        })
        this.propertiesData = properties;
      },
      error: (err: string) => {
        if (err.startsWith('objectStore does not exists')) {
          this.dbService.createObjectStore({
            store: 'saved-properties', storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
            storeSchema: []
          });
        }
      }
    });
  }

  saveProperty(uniqueId: any) {
    if (!isNaN(parseFloat(uniqueId)) && isFinite(Number(uniqueId))) {
      uniqueId = Number(uniqueId);
    }
    if (this.isSavedProperty(uniqueId)) {
      this.dbService.deleteByKey('saved-properties', uniqueId).subscribe(
        {
          error: (err: string) => {
            if (err.startsWith('objectStore does not exists')) {
              this.dbService.createObjectStore({
                store: 'saved-properties', storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
                storeSchema: []
              });
            }
          }
        }
      );
      const i = this.savedProperties.indexOf(uniqueId);
      if (i > -1) {
        this.savedProperties.splice(i, 1);
        this.propertiesData.splice(i, 1);
      }
    } else {
      this.dbService.getByKey('searched-properties', uniqueId).subscribe((property: PropertyModel | any) => {
        if (property) {
          let images = [];
          images.push(...property.media.map((f: any) => f.file));
          property.agents && property.agents.length > 0 ? images.push(property.agents[0].profileImg) : null;
          this.loadImages(images);
          this.dbService.add('saved-properties', property).subscribe();
          this.propertiesData.push(property);
        } else {
          this.dbService.getByKey('highlights-properties', uniqueId).subscribe((property: any) => {
            let images = [];
            images.push(...property.media.map((f: any) => f.file));
            property.agents && property.agents.length > 0 ? images.push(property.agents[0].profileImg) : null;
            this.loadImages(images);
            this.dbService.add('saved-properties', property).subscribe(
              {
                error: (err: string) => {
                  if (err.startsWith('objectStore does not exists')) {
                    this.dbService.createObjectStore({
                      store: 'saved-properties', storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
                      storeSchema: []
                    });
                  }
                }
              }
            );
            this.propertiesData.push(property);
          })
        }
      });
      this.savedProperties.push(uniqueId);
    }
  }

  loadImages(images: any[]) {
    this.eventService.broadcast('app-saved-images', images);
  }

  isSavedProperty(uniqueId: string) {
    return this.savedProperties.includes(uniqueId) ? true : false;
  }

  getSavedProperties() {
    return of(this.savedProperties);
  }

  getSavedPropertiesData() {
    return of(this.propertiesData);
  }
}
