import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsComponent2 } from './layouts.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { CookieService } from 'ngx-cookie-service';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InstallAppComponent } from './install-app/install-app.component';
import { LoginWarningComponent } from './login-warning/login-warning.component';
import { MobileNavMenuComponent } from './mobile-nav-menu/mobile-nav-menu.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
// import { LanguageService } from 'src/app/services/languages.service';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    LayoutsComponent2,
    HeaderComponent,
    FooterComponent,
    MobileMenuComponent,
    CookieConsentComponent,
    InstallAppComponent,
    LoginWarningComponent,
    MobileNavMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    NgbAccordionModule,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    TranslateModule,
    NgbDropdownModule,
    ReactiveFormsModule
  ],
  providers: [
    CookieService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LayoutsModule2 { }
