<!-- <ng-template #desktopContent let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="userModalLabel">Login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="login-modal-text text-center">
            <h6 class="mb-4">{{'LOGIN-WARN.TEXT' | translate}}</h6>
            <a routerLink="/account/login"><button (click)="modal.dismiss('Cross click')" class="btn login-warning-button">{{'LOGIN-PROCEED.TEXT' | translate}}</button></a>
        </div>
    </div>
</ng-template> -->

<ng-template #desktopContent let-modal>
    <div class="modal-header bg-light p-3">
        <h5 class="modal-title" id="userModalLabel">Login</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body login-warning">
        <form [formGroup]="loginForm" (ngSubmit)="submitLogin()" class="login-form px-md-5 px-4 py-3">
            <div class="form-group">
                <div class="text-center login-effect-text mb-2">
                    <span class="login-text" id="login-text-1"
                        [innerHTML]="'t-login-catchphrase' | translate"></span>
                    <span class="login-text" id="login-text-2"></span>
                </div>
            </div>
            <div class="form-group">
                <label class="login-label">{{'t-email' | translate}}</label>
                <input type="email" formControlName="email" [ngClass]="{
                    'is-invalid': submited && form['email'].errors
                  }" class="form-control" name="email" id="email">
                <div *ngIf="submited && form['email'].errors" class="invalid-feedback">
                    <div *ngIf="form['email'].errors['required']">
                        {{'t-validation-email' | translate}}
                    </div>
                    <div *ngIf="form['email'].errors['email']">
                        {{'t-validation-email-2' | translate}}
                    </div>
                </div>
                <i class="icon_mail_alt"></i>
            </div>
            <div class="form-group mt-1">
                <label class="login-label">{{'t-password' | translate}}</label>
                <input type="password" [ngClass]="{
                    'is-invalid': submited && form['password'].errors
                  }" formControlName="password" class="form-control" name="password" id="password" value="">
                <div *ngIf="submited && form['password'].errors" class="invalid-feedback">
                    <div *ngIf="form['password'].errors['required']">
                        {{'t-validation-password' | translate}}
                    </div>
                </div>
                <div *ngIf="incorrectLogin && form['password'].errors" class="invalid-feedback">
                    <div *ngIf="form['password'].errors['invalidCredentials']">
                        {{'t-wrong-credentials' | translate}}
                    </div>
                    <div *ngIf="form['password'].errors['anyError']">
                        {{'t-generic-error' | translate}}
                    </div>
                </div>
                <i class="icon_lock_alt"></i>
            </div>
            <div class="mt-1 mb-4 fl-wrap filter-tags clearfix add_bottom_30">
                <div class="float-right mt-1"><a id="forgot" routerLink="/account/change-password">{{'t-forgot-password' | translate}}</a></div>
            </div>
            <button class="btn white w-100 submit-button" type="submit" [attr.disabled]="isDisabled ? '' : null">
                <span *ngIf="!isDisabled">{{'t-sign-in' | translate}}</span>
                <span class="d-flex justify-content-center" *ngIf="isDisabled">
                    <span class="me-2">
                        {{'t-sign-in' | translate}}...
                    </span>
                    <span class="spinner-border flex-shrink-0" role="status">
                        <span class="visually-hidden">{{'t-sign-in' | translate}}...</span>
                    </span>
                </span>
            </button>

            <div class="text-center add_top_10 me-1 mt-2">{{'t-dont-have-account' | translate}}
                <a (click)="modal.dismiss()" [routerLink]="['/account/register']"> {{'t-sign-up' | translate}}</a>
            </div>
        </form>
    </div>
</ng-template>