import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { EntityService } from 'src/app/services/entity.service';
import { EventService } from 'src/app/services/event.service';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login-warning',
  templateUrl: './login-warning.component.html',
  styleUrls: ['./login-warning.component.css']
})
export class LoginWarningComponent implements OnInit {

  @ViewChild('desktopContent', { static: true }) desktopContent!: ElementRef;
  loginForm!: FormGroup;
  entityLogo!: string;
  pageTitle: string = "t-sign-in";
  submited: boolean = false;
  isDisabled: boolean = false;
  error = '';
  incorrectLogin: boolean = false;
  textInterval: any;
  subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    public offCanvasService: NgbOffcanvas,
    private eventService: EventService,
    private entityService: EntityService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private chatService: ChatService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.eventService.subscribe('open-login-warning', () => {
      this.openModal();
    });
    const entityData: any = localStorage.getItem('entityData');
    this.entityLogo = JSON.parse(entityData).logo;
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  openModal() {
    this.modalService.open(this.desktopContent, { centered: true });
    this.changeLoginText();
  }


  get form() { return this.loginForm.controls; }

  submitLogin() {
    this.submited = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    } else {
      this.isDisabled = true;
      const loginSub = this.authService.login(this.form['email'].value, this.form['password'].value).subscribe({
        next: (result) => {
          if (result.res === 1) {
            this.authService.getUser().subscribe((user) => {
              this.authService.saveUserToLocalStorage(user);
              // this.chatService.openConnection(user);
              this.isDisabled = false;
              this.submited = false;
              this.modalService.dismissAll();
              this.router.navigateByUrl('/');
            });
          } else if (result.res === 0) {
            if (result.appRef) {
              this.modalService.dismissAll();
              localStorage.setItem('otp-app-pending', result.appRef);
              this.router.navigate(['/account/verification-code']);
            }
          }
        }, error: err => {
          this.loginForm.controls['password'].setErrors({
            invalidCredentials: true
          })
          this.incorrectLogin = true;
          this.isDisabled = false;
        }
      });
      this.subscriptions.add(loginSub);
    }
  }

  changeLoginText() {
    const text: any = document.getElementById('login-text-2');
    let text1 = '';
    let text2 = '';
    let text3 = '';
    const translateSub = this.translateService.get(['t-login-text-1', 't-login-text-2', 't-login-text-3']).subscribe(data => {
      console.log(data);
    })
    this.subscriptions.add(translateSub);
    const texts = { '1': this.translateService.instant('t-login-text-1'), '2': this.translateService.instant('t-login-text-2'), '3': this.translateService.instant('t-login-text-3') };

    setTimeout(() => {
      text.textContent = texts[1];
    }, 0);
    setTimeout(() => {
      text.textContent = texts[2];
    }, 4000);
    setTimeout(() => {
      text.textContent = texts[3];
    }, 8000);

    this.textInterval = setInterval(() => {
      setTimeout(() => {
        text.textContent = texts[1];
      }, 0);
      setTimeout(() => {
        text.textContent = texts[2];
      }, 4000);
      setTimeout(() => {
        text.textContent = texts[3];
      }, 8000);
    }, 12000);
  }

  ngOnDestroy(): void {
    clearInterval(this.textInterval);
    this.subscriptions.unsubscribe();
  }


}
