<!-- Header Mobile -->
<header *ngIf="mobile && !headerNone" id="header-container" class="header head-tr" style="z-index: 999;">
  <div id="header"
    [style.backdrop-filter]="pageTitle !== 't-property' && pageTitle !== '' && pageTitle !== 't-enterprise' ? 'blur(10px)'  : null"
    [style.background-color]="pageTitle !== 't-property' && pageTitle !== 't-enterprise' ? 'var(--header-color) !important'  : 'transparent' "
    [style.background]="pageTitle === 't-property' || pageTitle === 't-enterprise' ? 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)) transparent' : pageTitle != '' ? 'var(--header-color) !important' : null"
    [ngClass]="{'responsive-rotate': pageTitle === 't-property' || pageTitle === 't-enterprise', 'header-white': headerWhite, 'home-page': pageTitle == ''}"
    class="head-tr bottom border-0">
    <div class="container container-header">
      <div *ngIf="mobile" class="left-side">
        <!-- Logo -->
        <div id="logo" [ngClass]="{'logo-horizontal': logoType === 'horizontal'}"
          [style.width]="pageTitle == 't-properties' || pageTitle == 'MLS' ? '65% !important':null" class="h-100">
          <a *ngIf="pageTitle == '' && entityData?.logo" routerLink="/" aria-label="Logo"
            class="align-items-center d-flex justify-content-center">
            <img *ngIf="!loadingService.getLoading()"
              src="{{transparentPagesLogo ? transparentPagesLogo : entityData.logo}}" width="45" data-sticky-logo=""
              alt="logo">
          </a>
          <h6
            *ngIf="pageTitle != '' && pageTitle != 't-property' && pageTitle !== 't-enterprise' && pageTitle !== 'MLS' && pageTitle != 't-properties'"
            class="header-page-title h-100 mb-0 mt-0 d-flex justify-content-center align-items-center">{{pageTitle |
            translate}}</h6>
          <!-- <h6 *ngIf="pageTitle != '' && pageTitle == 't-property' && !showCreditSim" class="header-page-title">
            {{pageTitle | translate}}</h6> -->
          <a *ngIf="pageTitle == 't-property' && showCreditSim" (click)="openCreditSimulator()">
            <h6 class="header-page-title">{{'t-creditSimulator' | translate}} <ion-icon class="header-credit-icon"
                src="assets/new-icons/chevron-down-outline.svg"></ion-icon>
            </h6>
          </a>
          <div class="h-100 d-flex align-items-center properties-input"
            *ngIf="pageTitle == 't-properties' || pageTitle == 'MLS'">
            <input [formControl]="inputSearch" class="form-control free-search-input"
              placeholder="{{'HOME.SEARCH.PLACEHOLDER.TEXT' | translate}}">
          </div>
        </div>
        <!-- Mobile Navigation -->
        <a class="header-back-button" *ngIf="pageTitle != ''" (click)="navigationService.goBack()">
          <ion-icon src="assets/new-icons/arrow-back-outline.svg"></ion-icon>
        </a>

        <app-mobile-menu *ngIf="pageTitle == '' && !loadingService.getLoading()" [entityLogo]="entityData?.logo"
          [hasEnterprises]="entityData.enterprises" [entityCountry]="entityData?.country"
          [language]="{'cookieValue':cookieValue, 'listLang': listLang, 'flag': flag, 'flagvalue': flagvalue, 'valueset': valueset, 'countryName': countryName }"></app-mobile-menu>
      </div>

      <div *ngIf="authService.currentUserValue" class="header-user-menu user-menu add user-drop pt-1"
        [ngClass]="tabletMode ? 'tablet-options' : null"
        (click)="openModal(userOptionsModal, 'md', 'user-options-modal')">
        <div class="header-user-name">
          <span><img class="border-0" src="{{user && user.photo ? user.photo : 'assets/images/user/default-user.jpg'}}"
              alt="{{user?.name}}">
            <div *ngIf="chatMessages > 0" class="user-notifications">{{chatMessages}}</div>
          </span>
        </div>
      </div>
      <div *ngIf="!authService.currentUserValue" class="header-user-menu user-menu add user-drop"
        [ngClass]="tabletMode ? 'tablet-options' : null">
        <div class="header-user-name pt-1">
          <a routerLink="/account/login"><ion-icon class="offline-user"
              src="assets/new-icons/person-circle-outline.svg"></ion-icon></a>
        </div>
      </div>
    </div>
  </div>
</header>

<!--Header Desktop-->
<header *ngIf="!mobile && !headerNone" id="header-container" class="header head-tr" style="z-index: 999;">
  <div id="header"
    [ngClass]="{'responsive-rotate': pageTitle === 't-property', 'header-white': headerWhite, 'change-header': pageTitle == 't-properties' || pageTitle == 't-property' || pageTitle == 't-contacts' || pageTitle == 't-enterprises' || pageTitle == 't-enterprise' || pageTitle == 't-portfolio'}"
    class="head-tr bottom border-0">
    <div [ngClass]="{'black-header': blackHeader}" class="container ps-5 container-header">
      <!-- Left Side Desktop -->
      <div [ngClass]="{'logo-horizontal': logoType === 'horizontal'}"
        class="align-items-center w-100 position-relative d-flex h-100">
        <!-- Logo -->
        <div id="logo" [ngClass]="tabletMode ? 'logo-tablet' : null" class="ms-4 me-0 mt-0">
          <a routerLink="/" class="logo">
            <img src="{{getLogo()}}" [ngClass]="{'d-none': tabletMode && pageTitle == 't-property'}" alt="Logo">
          </a>
          <a *ngIf="pageTitle == 't-property' && showCreditSim && tabletMode" (click)="openCreditSimulator()">
            <h6 class="header-page-title">{{'t-creditSimulator' | translate}} <ion-icon class="header-credit-icon"
                src="assets/new-icons/chevron-down-outline.svg"></ion-icon>
            </h6>
          </a>
        </div>
        <app-mobile-menu *ngIf="tabletMode && !loadingService.getLoading()" [hasEnterprises]="entityData.enterprises"
          [language]="{'cookieValue':cookieValue, 'listLang': listLang, 'flag': flag, 'flagvalue': flagvalue[0], 'valueset': valueset, 'countryName': countryName }"></app-mobile-menu>
        <nav id="navigation" *ngIf="languageService.showMenu && !tabletMode" class="style-1 ps-4 mt-0 head-tr">
          <ul id="responsive">
            <li>
              <a class="menu-item" routerLink="/">
                <span [ngClass]="{'active-route pb-1': pageTitle === ''}">
                  {{'MENUITEMS.HOME.TEXT' | translate}}
                </span>
              </a>
            </li>
            <li>
              <a class="menu-item" routerLink="/about-us">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-about-us'}">
                  {{'MENUITEMS.ABOUT-US.TEXT' | translate}}
                </span>
              </a>
            </li>
            <li *ngIf="entityData?.enterprises">
              <a class="menu-item" routerLink="/enterprises">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-enterprises'}">
                  {{'t-enterprises' | translate}}
                </span>
              </a>
            </li>
            <li>
              <a class="menu-item" routerLink="/properties">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-properties'}">
                  {{'MENUITEMS.PROPERTIES.TEXT' | translate}}
                </span>
              </a>
            </li>
            <li>
              <a class="menu-item" routerLink="/portfolio">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-portfolio'}">
                  {{'t-portfolio' | translate}}
                </span>
              </a>
            </li>
            <!-- <li>
              <a class="menu-item" routerLink="/sell-properties">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-sell-properties'}">
                  {{'MENUITEMS.SELL-PROPERTIES.TEXT' | translate}}
                </span>
              </a>
            </li> -->
            <li>
              <a class="menu-item" routerLink="/contacts">
                <span [ngClass]="{'active-route pb-1': pageTitle === 't-contacts'}">
                  {{'MENUITEMS.CONTACTS.TEXT' | translate}}
                </span>
              </a>
            </li>
          </ul>
        </nav>
        <div class="icons-header">
          <div *ngIf="authService.currentUserValue" [ngClass]="userDrop ? 'active' : null"
            class="header-user-menu user-menu add user-drop top-0 p-0 m-0 h-100 d-flex align-items-center"
            [ngClass]="tabletMode ? 'tablet-options' : null" (click)="userDrop = !userDrop">
            <div
              class="add align-items-center d-lg-flex d-none d-xl-flex dropdown h-100 header-item header-user-menu language-selector ms-1 saved-prop-header topbar-head-dropdown user-menu">
              <button type="button"
                class="btn btn-icon user-img btn-topbar dropdown-toggle btn-ghost-secondary rounded-circle d-flex align-items-center justify-content-center"
                aria-haspopup="true" aria-expanded="false">
                <img class="border-0 rounded-circle w-100 h-100"
                  src="{{user && user.photo ? user.photo : 'assets/images/user/default-user.jpg'}}"
                  alt="{{user?.name}}">
                <div class="logged-signal"></div>
              </button>
            </div>
            <!-- <div class="header-user-name">
          <span><img class="border-0" src="{{user && user.photo ? user.photo : 'assets/images/user/default-user.jpg'}}"
              alt=""></span>
          <div *ngIf="user && user.name" class="username-header">{{'t-hello' | translate}}, {{user.name}}!</div>
        </div> -->
            <ul class="p-1" *ngIf="user?.role == 'AGENT' || user?.role == 'MASTER'">
              <li><a class="p-2" routerLink="/agent-area/dashboard">
                  <ion-icon class="me-1" name="stats-chart-outline"></ion-icon>{{'MENUITEMS.MY-DASHBOARD.TEXT' |
                  translate}}
                </a></li>
              <li *ngIf="entityData.hasChat && user?.role == 'AGENT'"><a class="p-2 d-flex align-items-center"
                  routerLink="/chat">
                  <ion-icon class="me-1" src="assets/new-icons/chatbubble-ellipses-outline.svg"></ion-icon>{{'t-chat' |
                  translate}}
                </a></li>
              <!-- <li><a class="p-2 d-flex align-items-center" routerLink="/mls">
                  <ion-icon class="me-1" src="assets/new-icons/mls.svg"></ion-icon>MLS Imodigi
                </a></li> -->
              <li><a class="p-2 d-flex align-items-center" routerLink="/account/change-password">
                  <ion-icon class="me-1"
                    src="assets/new-icons/repeat-outline.svg"></ion-icon>{{'MENUITEMS.CHANGE-PASS.TEXT'
                  | translate}}
                </a></li>
              <li class="border-0"><a class="p-2 d-flex align-items-center" (click)="logoutUser()">
                  <ion-icon class="me-1" src="assets/new-icons/log-out-outline.svg"></ion-icon>{{'MENUITEMS.LOGOUT.TEXT'
                  |
                  translate}}
                </a></li>
            </ul>
            <ul class="p-1" *ngIf="user?.role == 'USERS'">
              <li *ngIf="entityData.hasChat"><a class="p-2 d-flex align-items-center" routerLink="/chat">
                  <ion-icon class="me-1" src="assets/new-icons/chatbubble-ellipses-outline.svg"></ion-icon>{{'t-chat' |
                  translate}}
                </a></li>
              <li><a class="p-2 d-flex align-items-center" routerLink="/account/change-password">
                  <ion-icon class="me-1"
                    src="assets/new-icons/repeat-outline.svg"></ion-icon>{{'MENUITEMS.CHANGE-PASS.TEXT'
                  | translate}}
                </a></li>
              <li class="border-0"><a class="p-2 d-flex align-items-center" (click)="logoutUser()">
                  <ion-icon class="me-1" src="assets/new-icons/log-out-outline.svg"></ion-icon>{{'MENUITEMS.LOGOUT.TEXT'
                  |
                  translate}}
                </a></li>
            </ul>
          </div>

          <div *ngIf="!authService.currentUserValue"
            class="add align-items-center d-lg-flex d-none d-xl-flex dropdown h-100 header-item header-user-menu language-selector ms-1 saved-prop-header topbar-head-dropdown user-menu">
            <a routerLink="/account/login">
              <button type="button"
                class="btn btn-icon btn-topbar dropdown-toggle btn-ghost-secondary rounded-circle d-flex align-items-center justify-content-center"
                aria-haspopup="true" aria-expanded="false">
                <ion-icon class="fs-4" src="assets/new-icons/person-outline.svg"></ion-icon>
              </button>
            </a>
          </div>
          <!-- Right Side Content / End -->

          <!-- lang-wrap-->
          <div *ngIf="listLang.length > 0"
            class="add align-items-center d-lg-flex d-none d-xl-flex h-100 header-item header-user-menu language-selector ms-1 topbar-head-dropdown user-menu dropdown"
            ngbDropdown>
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
              <span class="text-lang" *ngIf="flagvalue !== undefined">{{flagvalue}}</span>
              <span class="text-lang" *ngIf="flagvalue === undefined">{{valueset}}</span>
              <!-- <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="20" class="rounded">
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="20" class="rounded"> -->
            </button>
            <div class="dropdown-menu dropdown-menu-end py-1 px-0" ngbDropdownMenu>

              <!-- item-->
              <a class="dropdown-item notify-item language py-2" data-lang="eng" *ngFor="let item of listLang"
                (click)="setLanguage(item.text, item.lang, item.flag)"
                [ngClass]="{'active': cookieValue === item.lang}">
                <img src="{{item.flag}}" alt="{{item.text}} Language" class="me-2 rounded w-25" height="18"> <span
                  class="align-middle">{{item.text}}</span>
              </a>
            </div>
          </div>

          <div
            class="add align-items-center d-lg-flex d-none d-xl-flex dropdown h-100 header-item header-user-menu language-selector ms-1 saved-prop-header topbar-head-dropdown user-menu">
            <a routerLink="/saved-properties">
              <button type="button"
                class="btn btn-icon btn-topbar dropdown-toggle btn-ghost-secondary rounded-circle d-flex align-items-center justify-content-center"
                aria-haspopup="true" aria-expanded="false">
                <ion-icon class="fs-4" src="assets/new-icons/heart-outline.svg"></ion-icon>
              </button>
            </a>
          </div>

          <div (click)="openModal(latestSearchesModal, 'md', 'latest-searches-modal')"
            class="dropdown h-100 align-items-center ms-1 topbar-head-dropdown header-item header-user-menu user-menu add d-none d-lg-none language-selector saved-prop-header d-xl-flex">
            <button type="button"
              class="btn btn-icon btn-topbar dropdown-toggle btn-ghost-secondary rounded-circle d-flex align-items-center justify-content-center"
              aria-haspopup="true" aria-expanded="false">
              <ion-icon class="fs-4" src="assets/new-icons/history.svg"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="clearfix"></div>


<ng-template #userOptionsModal let-modal>
  <div class="modal-body px-0">
    <form>
      <div class="pe-3 row">
        <div class="col-12">
          <button type="button" id="close-mobile-menu" class="btn-close float-end" aria-label="Close"
            (click)="modal.dismiss()"></button>
        </div>
      </div>
      <div class="modal-user-options mt-3">
        <div class="d-flex pb-2 ps-4 user-data">
          <img width="45" class="rounded-circle me-3"
            src="{{user && user.photo ? user.photo : 'assets/images/user/default-user.jpg'}}" alt="{{user?.name}}">
          <div class="user-info">
            <div class="fw-bold" *ngIf="user && user.name">{{user.name}}</div>
            <div *ngIf="user && user.email">{{user.email}}</div>
          </div>
        </div>
        <ul class="user-options ps-0 mt-3">
          <li *ngIf="user?.role == 'AGENT' || user?.role == 'MASTER'" routerLink="/agent-area"
            [ngClass]="{'pointer-none': isOnline === 0}" (click)="modal.dismiss()"
            class="align-items-center d-flex ps-4 user-option">
            <ion-icon class="fs-4 me-3" name="stats-chart-outline"></ion-icon>
            <ion-icon *ngIf="isOnline === 0" class="fs-4 me-3"
              src="assets/new-icons/cloud-offline-outline.svg"></ion-icon><span>{{'MENUITEMS.MY-DASHBOARD.TEXT' |
              translate}}</span>
            <!-- <div *ngIf="chatMessages > 0" class="chat-user-notifications">{{chatMessages}}</div> -->
          </li>
          <li *ngIf="entityData.hasChat && (user?.role == 'AGENT' || user?.role == 'USERS')" routerLink="/chat"
            [ngClass]="{'pointer-none': isOnline === 0}" (click)="modal.dismiss()"
            class="align-items-center d-flex ps-4 user-option">
            <ion-icon *ngIf="isOnline === 1" class="fs-4 me-3"
              src="assets/new-icons/chatbubble-ellipses-outline.svg"></ion-icon>
            <ion-icon *ngIf="isOnline === 0" class="fs-4 me-3"
              src="assets/new-icons/cloud-offline-outline.svg"></ion-icon><span>{{'t-chat' |
              translate}}</span>
            <div *ngIf="chatMessages > 0" class="chat-user-notifications">{{chatMessages}}</div>
          </li>
          <!-- <li *ngIf="user?.role == 'AGENT' || user?.role == 'MASTER'" routerLink="/mls" (click)="modal.dismiss()"
            class="align-items-center d-flex ps-4 user-option">
            <ion-icon *ngIf="isOnline === 1" class="fs-4 me-3" src="assets/new-icons/mls.svg"></ion-icon>
            <span>MLS Imodigi</span>
          </li> -->
          <li routerLink="/account/change-password" [ngClass]="{'pointer-none': isOnline === 0}"
            (click)="modal.dismiss()" class="align-items-center d-flex ps-4 user-option">
            <ion-icon *ngIf="isOnline === 1" class="fs-4 me-3" src="assets/new-icons/repeat-outline.svg"></ion-icon>
            <ion-icon *ngIf="isOnline === 0" class="fs-4 me-3"
              src="assets/new-icons/cloud-offline-outline.svg"></ion-icon>
            <span>{{'MENUITEMS.CHANGE-PASS.TEXT'
              | translate}}</span>
          </li>
          <li (click)="logoutUser(); modal.dismiss()" class="align-items-center d-flex ps-4 user-option">
            <ion-icon class="fs-4 me-3" src="assets/new-icons/log-out-outline.svg"></ion-icon>
            <span>{{'MENUITEMS.LOGOUT.TEXT' | translate}}</span>
          </li>
        </ul>
      </div>
    </form>

  </div>

</ng-template>

<ng-template #latestSearchesModal let-modal>
  <div class="modal-header px-5">
    <ion-icon src="assets/new-icons/history.svg" class="fs-4 me-2"></ion-icon>
    <h5 class="mb-0 text-capitalize">{{'t-latest-searches' | translate}}</h5>
    <button type="button" id="close-mobile-menu" class="btn-close float-end" aria-label="Close"
      (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-body px-5 pb-0">
    <form>
      <div class="modal-latest-searches">
        <div *ngIf="latestSearches.length > 0">
          <ul class="list-unstyled ps-4">
            <li *ngFor="let result of latestSearches; let i = index"
              (click)="searchWithLatest(result.searchKey, result.type)"
              [ngClass]="{'border-bottom': i != latestSearches.length - 1}"
              class="c-pointer ng-star-inserted option-result pb-3 position-relative pt-2">
              <span class="fw-bold result-title">{{result?.type == 'freeSearch' ? result?.searchKey :
                result.name}}</span>
              <br>
              <span class="result-subtitle">{{'PROPERTIES.SEARCH-RESULTS.TEXT' | translate}}:
                {{result?.total}}</span>
              <ion-icon class="clear-search fs-5" (click)="clearSearch = {
                isClear: true, result: result.searchKey
            }" src="assets/new-icons/trash-outline.svg"></ion-icon>
            </li>
          </ul>
        </div>
        <div *ngIf="latestSearches.length === 0" class="pb-4">
          <span>{{'t-no-searches' | translate}}</span>
        </div>
      </div>
    </form>

  </div>

</ng-template>

<div class="d-none" *ngIf="flag">
  <img [src]="flag">
</div>
<!-- Header Container / End -->
