import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, first, map, of, tap } from "rxjs";
import { environment } from "src/environments/environment";
import { User } from "../models/auth.model";
import { Socket } from "ngx-socketio2";
import { ChatMessage, ChatUser } from "../models/chat.model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ChatService extends Socket {
  public usersOnline$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public chatDisconnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  checkInterval: any;
  forceDisconnect: boolean = false;
  user!: User;

  constructor(private http: HttpClient, private authService: AuthService) {
    super({
      url: environment.chatUrl,
      options: {
        autoConnect: false,
        withCredentials: true,
        path: '/chatv2/socket.io',
        // auth: {
        //   token: 'token'
        // }
      }
    })
  }

  /**
   * Get data
   */

  getAllUsers() {
    return this.http.get<ChatUser[]>(`${environment.apiUrl}/chat/users`, { withCredentials: true })
      .pipe(first(), map(data => data),
        catchError(error => { return of<ChatUser[]>([]) }));
  }

  getUserByEmail(email: string, propertyId: any) {
    let params = new HttpParams().set('email', email).set('property', propertyId);
    return this.http.get<ChatUser>(`${environment.apiUrl}/chat/user`, { params, withCredentials: true })
      .pipe(first(), map(data => data),
        catchError(error => { return of<ChatUser>() }));
  }


  getMessages(uc: any) {
    return this.http.get<ChatMessage[]>(`${environment.apiUrl}/chat/messages/${uc}`, { withCredentials: true })
      .pipe(first(), map(data => data),
        catchError(error => { return of<ChatMessage[]>([]) }));
  }

  /**
   * Connection
   */

  openConnection(user: User) {
    const token = this.authService.getToken();
    this.auth = {
      token: token?.token,
      refreshToken: token?.refresh
    }
    this.user = user;
    // if (!location.href.includes('localhost')) {
    // this.connect();
    // this.chatUsersOnline();
    // this.checkConnection();
    // }
  }

  closeConnection() {
    this.forceDisconnect = true;
    this.disconnect();
    setTimeout(() => {
      this.forceDisconnect = false;
    }, 5000);
  }

  checkConnection() {
    this.on('disconnect').subscribe({
      next: () => {
        console.log('chat disconnect');
      }
    })
  }

  verifyChatStatus() {
    if (this.disconnected && !this.checkInterval) {
      this.chatDisconnected$.next(true);
      this.connect();
      this.checkInterval = setInterval(() => {
        if (this.connected) {
          this.chatDisconnected$.next(false);
          clearInterval(this.checkInterval);
        }
      });
    }
  }

  /**
   * Receive Info
   */

  receiveMessage() {
    return this.on<any>('receive_message').pipe(
      tap((args: any) => {
        this.chatDisconnected$.next(false);
      })
    );
  }

  receiveMessageFile() {
    return this.on<any>('receive_message_file').pipe(
      tap((args: any) => {
        this.chatDisconnected$.next(false);
      })
    );
  }

  chatUsersOnline() {
    this.on<any>('chat_users_online').subscribe({
      next: (data: any[]) => {
        console.log(data);
        if (!data.some(d => d.id === this.user.userAppId) && !this.forceDisconnect) {
          this.disconnect();
          this.connect();
        }
        this.chatDisconnected$.next(false);
        this.usersOnline$.next(data);
      },
    });
  }

  viewedMessage() {
    return this.on<any>('viewed_message').pipe(
      tap((args: any) => {
        this.chatDisconnected$.next(false);
      })
    );
  }

  receiveDeleteChat() {
    return this.on<any>('receive_delete_chat');
  }

  receiveMessageDeleteFiles() {
    return this.on<any>('receive_message_delete_files');
  }

  receiveMessageDelete() {
    return this.on<any>('receive_message_delete');
  }

  errors() {
    return this.on('error').pipe(
      tap((args: any) => {
        this.chatDisconnected$.next(false);
        console.log('error: ', args);
      })
    )
  }

  /**
   * Send Info
   */

  readMessage(data: any) {
    this.emit('read_message', data);
  }

  sendMessage(data: any) {
    if (this.disconnected) { }
    this.emit('send_message', data);
  }

  deleteChat(data: any) {
    this.emit('delete_chat', data);
  }

  deleteMessage(data: any) {
    this.emit('delete_messages', data);
  }

  sendFiles(data: any) {
    this.emit('send_files', data);
  }

  deleteFiles(data: any) {
    this.emit('delete_messages_files', data);
  }

  /**
   * Auxiliar Functions
   */

  async getTotalFileSizeInMB(files: File[]): Promise<number> {
    let totalSize = 0;
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      totalSize += await this.getFileSizeInMB(file);
    }
    return totalSize;
  }

  async getFileSizeInMB(file: File): Promise<number> {
    return new Promise((resolve) => {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024); // Convertendo bytes para megabytes
      resolve(fileSizeInMB);
    });
  }

}
