import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PwaService } from 'src/app/services/pwa.service';
import { EventService } from 'src/app/services/event.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-install-app',
  templateUrl: './install-app.component.html',
  styleUrls: ['./install-app.component.css']
})
export class InstallAppComponent implements OnInit {

  @ViewChild('contentAndroid', { static: true }) contentAndroid!: ElementRef;
  @ViewChild('contentIOS', { static: true }) contentIOS!: ElementRef;
  data!: { mobileType: 'ios' | 'android', promptEvent?: any };
  public slideOpts = {
    initialSlide: 1,
    speed: 400
  };
  lang: any;

  constructor(
    private modalService: NgbModal,
    private pwaService: PwaService,
    private eventService: EventService,
    private cookieService: CookieService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.lang = this.cookieService.check('lang') ? this.cookieService.get('lang') : this.translateService.currentLang;
    this.pwaService.componentInit.next(true);
    this.eventService.subscribe('open-install-app', (result: { data: { mobileType: 'ios' | 'android', promptEvent?: any } }) => {
      this.data = result.data;
      if (result.data.mobileType === 'android') {
        this.openModal(this.contentAndroid);
      } else if (result.data.mobileType === 'ios') {
        this.openModalIOS(this.contentIOS);
      }
    })
  }

  nextSlide(container: any) {
    container.swiper.slideNext();
  }

  openModalIOS(content: any) {
    this.modalService.open(content, { size: 'fullscreen', fullscreen: true, centered: true, keyboard: false, scrollable: true, backdrop: 'static' });
  }

  openModal(content: any) {
    this.modalService.open(content, { size: 'md', centered: true, keyboard: false, scrollable: true, backdrop: 'static' });
  }

  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.modalService.dismissAll();
  }

  continueOnWebSite() {
    this.pwaService.setTempCookie(true);
    this.modalService.dismissAll();
  }

}
