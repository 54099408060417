import { Component, Input, OnInit } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageService } from 'src/app/services/languages.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PwaService } from 'src/app/services/pwa.service';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.css']
})
export class MobileMenuComponent implements OnInit {

  public version = packageJson.version;
  displayMode: any;
  showPrompt: boolean = false;
  @Input() entityLogo: any;
  @Input() entityCountry: any;
  @Input() hasEnterprises: boolean = false;
  @Input() language: any;


  constructor(
    private canvasService: NgbOffcanvas,
    public loadingService: LoadingService,
    public authService: AuthService,
    public pwaService: PwaService,
    private languageService: LanguageService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.pwaService.cookieValue.subscribe(value => this.showPrompt = value);
    this.displayMode = localStorage.getItem('display-mode');
    if (!this.language.flag) {
      if (this.cookieService.check('lang'))
        this.language.flag = `assets/images/flags/${this.cookieService.get('lang')}.svg`;
    }
  }

  openOffCanvas(content: any) {
    this.canvasService.open(content);
  }

  closeAll() {
    document.getElementById('close-mobile-menu')?.click();
  }

  installPwa() {
    this.pwaService.addToHomeEvent();
    this.canvasService.dismiss();
  }

  /***
  * Language Value Set
  */
  setLanguage(text: string, lang: string, flag: string) {
    this.language.countryName = text;
    this.language.flag = flag;
    this.language.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

}
