import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LayoutsModule } from './template1/layouts/layouts.module';
import { LayoutsModule2 } from './template2/layouts/layouts.module';
import { LayoutsModule3 } from './template3/layouts/layouts.module';
import { LayoutsModule4 } from './template4/layouts/layouts.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { FormsModule } from '@angular/forms';
import { PwaService } from './services/pwa.service';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { SavedPropertiesService } from './services/saved-properties.service';
import { IonicModule } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { PropertyPrintModule } from './template1/pages/property/property-print/property-print.module';
import { OnlineStatusModule } from 'ngx-online-status';
import { NgxTranslateRoutesModule } from 'ngx-translate-routes';

// import { LanguageService } from './services/languages.service';
// register Swiper custom elements
register();

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();
const savedInitializer = (savedService: SavedPropertiesService) => () => savedService.initSavedService();

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, `${environment.apiUrl}/languages/`, '.json');
}

const dbConfig: DBConfig = {
  name: 'imodigi-db',
  version: 2,
  objectStoresMeta: [{
    store: 'saved-properties',
    storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
    storeSchema: []
  },
  {
    store: 'highlights-properties',
    storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
    storeSchema: []
  },
  {
    store: 'translations',
    storeConfig: { keyPath: 'lang', autoIncrement: false },
    storeSchema: []
  },
  {
    store: 'searched-properties',
    storeConfig: { keyPath: 'uniqueId', autoIncrement: false },
    storeSchema: []
  },
  {
    store: 'latest-searches',
    storeConfig: { keyPath: 'searchKey', autoIncrement: false },
    storeSchema: []
  },
  {
    store: 'latest-popular-cities',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: []
  }
  ],
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutsModule,
    LayoutsModule2,
    LayoutsModule4,
    FormsModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   //registrationStrategy: 'registerWhenStable:30000'
    // }),
    HttpClientModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    IonicModule.forRoot(),
    PropertyPrintModule,
    OnlineStatusModule,
    NgxTranslateRoutesModule.forRoot({
      enableRouteTranslate: true,
      enableTitleTranslate: false
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true },
    { provide: APP_INITIALIZER, useFactory: savedInitializer, deps: [SavedPropertiesService], multi: true },
    // LanguageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
