import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataminingModel } from '../models/datamining.model';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class DataminingService {
  sessionData!: DataminingModel;
  appPrompt: boolean = false;
  coords: any;
  entityId: any = document.getElementById('aux-env-input');

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private translateService: TranslateService,
  ) { }

  getCookieValue() {
    const value: any = this.cookieService.get('PWA-PROMPT');
    this.appPrompt = value;
  }

  sendData(accessData: Navigator, action: string, options: any = {}, property = null) {
    this.getCookieValue();
    const displayMode: any = localStorage.getItem('display-mode');
    const session = sessionStorage.getItem('session') ? sessionStorage.getItem('session') : this.setSession();
    const lang = this.cookieService.check('lang') ? this.cookieService.get('lang') : this.translateService.currentLang;
    const device = this.getDevice(accessData.userAgent);
    const deviceType = this.getDeviceType(accessData.userAgent.toLowerCase());
    this.coords = localStorage.getItem('latlng');
    if (this.coords) {
      this.coords = JSON.parse(this.coords);
    }
    this.sessionData = {
      entityId: Number(this.entityId.value),
      action: action,
      sessionId: session ? session : '',
      deviceOS: device,
      deviceType: deviceType,
      installedApp: this.checkInstalledApp(device, displayMode),
      options: options,
      selectedLang: lang ? lang : accessData.language,
      latitude: this.coords?.lat ? this.coords.lat + '' : '',
      longitude: this.coords?.lng ? this.coords.lng + '' : '',
      property: property
    };
    return this.http
      .post<any>(`${environment.apiUrl}/datamining/send-data`, this.sessionData)
      .subscribe({
        next: (response: any) => {
          let itemsToSend = localStorage.getItem('datamining-offline');
          if (itemsToSend) {
            const items: any[] = JSON.parse(itemsToSend);
            if (items.length > 0) {
              items.forEach((item) => {
                this.http
                  .post<any>(
                    `${environment.apiUrl}/datamining/send-data`,
                    item
                  )
                  .subscribe();
              });
              localStorage.removeItem('datamining-offline');
            }
          }
        },
        error: (err: any) => {
          let itemsToSend = localStorage.getItem('datamining-offline');
          this.sessionData.createdAt = new Date();
          if (itemsToSend) {
            const items: any[] = JSON.parse(itemsToSend);
            items.push(this.sessionData);
            localStorage.setItem('datamining-offline', JSON.stringify(items));
          } else {
            const items = [];
            items.push(this.sessionData);
            localStorage.setItem('datamining-offline', JSON.stringify(items));
          }
        },
      });
  }

  checkInstalledApp(device: string, displayMode: string) {
    if (device !== 'IOS') {
      return displayMode === 'standalone' && !this.appPrompt ? 'true' : 'false';
    } else {
      const n: any = navigator;
      return 'standalone' in window.navigator && n?.standalone === true ? 'true' : 'false';
    }
  }

  setSession() {
    let session = new Date() + window.navigator.userAgent;
    session = CryptoJS.SHA256(session).toString();
    sessionStorage.setItem('session', session);
    return session;
  }

  getDevice(userAgent: any) {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    return /iPhone|iPad|iPod/i.test(userAgent)
      ? 'IOS'
      : /Android/i.test(userAgent)
        ? 'Android'
        : /Windows/i.test(userAgent)
          ? 'Windows'
          : /Linux/i.test(userAgent)
            ? 'Linux'
            : /Mac OS/i.test(userAgent)
              ? width <= 1025 ? 'IOS'
                : 'Mac OS'
              : 'Other';
  }

  getDeviceType(userAgent: any) {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    if (width < 480) {
      return 'Mobile';
    } else {
      const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
      if (isTablet) {
        return 'Tablet';
      } else {
        if (width <= 1300 && /Mac OS/i.test(userAgent) && navigator.maxTouchPoints > 1) {
          return 'Tablet';
        }
        return 'Desktop';
      }
    }
  }
}
