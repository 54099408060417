import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../services/auth.service';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService, private router: Router
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/refresh') > -1) {
      const t = this.authService.getToken();
      if (t) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + t.token).set('x-refresh-token', t.refresh + '')
        });
        return next.handle(cloned);
      } else {
        return next.handle(req);
      }
    }
    if (req.withCredentials || req.url.indexOf('/chatv2') > -1) {
      const t = this.authService.getToken();
      if (t) {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + t.token).set('x-refresh-token', t.refresh + '')
        });
        return next.handle(cloned).pipe(
          catchError((err) => {
            if (err.status == 401) {
              return this.handle401Error(req, next, err);
            } else {
              const error = err.error.message || err.statusText;
              return throwError(error);
            }
          })
        );
      } else {
        return next.handle(req).pipe(
          catchError((err) => {
            if (err.status == 401) {
              return this.handle401Error(req, next, err);
            } else {
              const error = err.error.message || err.statusText;
              return throwError(error);
            }
          })
        );
      }
    } else {
      return next.handle(req);
    }
  }

  /**
   * check refresh if Invalid user token
   * @param req
   * @param next
   * @param originalError
   * @returns
   */
  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler,
    originalError: any
  ) {
    return this.authService.refreshCookie().pipe(
      switchMap(() => {
        return next.handle(req);
      }),
      catchError((err) => {
        // this.authService.removeUserToLocalStorage();
        this.authService.logout('');
        this.router.navigate(['/login']);
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }

}
