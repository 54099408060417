import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { debounceTime, fromEvent, Observable, Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { routeAnimations } from '../pages/page-animation';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.css'],
  animations: [
    routeAnimations
  ]
})
export class LayoutsComponent2 implements OnInit, OnDestroy {

  mobile: boolean = false;
  resizeObservable$!: Observable<Event>;
  resizeSubscription$!: Subscription;


  constructor(public loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadCss();
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.pipe(debounceTime(100)).subscribe((evt: any) => {
      if (evt?.isTrusted) {
        this.onResize(evt.target);
      } else {
        this.onResize();
      }
    });
    this.onResize();
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  onResize(event: any = null) {
    let width: any;
    let orientation: any;

    if (event === null) {
      width = window.innerWidth;
      orientation = 0;
    } else {
      width = event.outerWidth;
      orientation = event?.screen?.orientation?.angle;
    };

    if (width < 900 || orientation != 0) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }

  };

  loadCss() {
    const dynamicCss = [
      "assets/css/menu.css",
    ];

    for (let i = 0; i < dynamicCss.length; i++) {
      const node = document.createElement('link');
      node.rel = "stylesheet";
      node.href = dynamicCss[i];
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }

  ngOnDestroy(): void {
    this.resizeSubscription$.unsubscribe();
  }

}
