import { trigger, animate, transition, style, group, query, animateChild, keyframes } from '@angular/animations';

//https://fireship.io/lessons/angular-router-animations/      --router animations

export const slideInAnimation = trigger('slideInAnimation', [
  // Transition between any two states
  transition('* <=> *', [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%'
      })
    ], { optional: true }),
    transition(':enter', [
      style({ transform: 'translateX(-100%)' }),
      animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
    ]),
    transition(':leave', [
      animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
    ])
  ]),
]);

export const routeAnimations =
  trigger('routeAnimations', [
    // transition('HomePage => Properties', slideTo('right')),
    transition('HomePage => Properties', slideToRight()),
    transition('HomePage => Property', slideToRight()),
    transition('HomePage => Enterprise', slideToRight()),
    transition('HomePage => Enterprises', slideToRight()),
    transition('HomePage => AboutUs', slideToRight()),
    transition('HomePage => Contacts', slideToRight()),
    transition('HomePage => Login', slideToRight()),
    transition('HomePage => Register', slideToRight()),
    transition('HomePage => Chat', slideToRight()),
    transition('HomePage => agentDashboard', slideToRight()),
    transition('HomePage => agentProfile', slideToRight()),
    transition('HomePage => agentProperties', slideToRight()),
    transition('HomePage => SavedProperties', slideToRight()),
    transition('HomePage => AlternativeResolution', slideToRight()),
    transition('HomePage => ManageData', slideToRight()),
    transition('HomePage => SellProperties', slideToRight()),
    transition('HomePage => PrivacyPolicy', slideToRight()),
    transition('HomePage => TermsConditions', slideToLeft()),
    transition('* => changePass', slideToRight()),
    // transition('Properties => HomePage', slideTo('left')),
    transition('Properties => HomePage', slideToLeft()),
    transition('Properties => Property', slideToRight()),
    transition('Properties => Enterprise', slideToRight()),
    transition('Properties => SavedProperties', slideToRight()),
    transition('Property => Properties', slideToLeft()),
    transition('Property => HomePage', slideToLeft()),
    transition('Property => SavedProperties', slideToRight()),
    transition('Property => Login', slideToRight()),
    transition('AboutUs => HomePage', slideToLeft()),
    transition('Contacts => HomePage', slideToLeft()),
    transition('agentDashboard => agentCalendar', slideToRight()),
    transition('agentDashboard => agentProperties', slideToRight()),
    transition('agentCalendar => agentDashboard', slideToLeft()),
    transition('agentCalendar => agentProperties', slideToRight()),
    transition('agentProperties => agentCalendar', slideToLeft()),
    transition('agentProperties => agentDashboard', slideToLeft()),
    transition('SavedProperties => *', slideToLeft()),
    transition('TermsConditions => HomePage', slideToLeft()),
    transition('PrivacyPolicy => HomePage', slideToLeft()),
    transition('AlternativeResolution => HomePage', slideToLeft()),
    transition('ManageData => HomePage', slideToLeft()),
    transition('SellProperties => HomePage', slideToLeft()),
    transition('Chat => HomePage', slideToLeft()),
    transition('Login => HomePage', slideToLeft()),
    transition('Login => Enterprises', slideToLeft()),
    transition('Login => Enterprise', slideToLeft()),
    transition('Enterprises => HomePage', slideToLeft()),
    transition('Enterprises => Property', slideToRight()),
    transition('Enterprises => Enterprise', verticalSlideToTop()),
    transition('Enterprises => Login', slideToRight()),
    transition('Enterprises => Chat', slideToRight()),
    transition('Enterprise => Enterprises', verticalSlideToBottom()),
    transition('Property => Enterprises', slideToLeft()),
    transition('Enterprise => Property', slideToRight()),
    transition('Enterprise => Login', slideToRight()),
    transition('Enterprise => Chat', slideToRight()),
    transition('Enterprise => HomePage', slideToLeft()),
    transition('Enterprise => Properties', slideToLeft()),
    transition('Enterprise => SavedProperties', slideToLeft()),
    transition('Property => Enterprise', slideToLeft()),
    transition('Properties => Enterprises', slideToRight()),
    transition('changePass => *', slideToLeft()),
    transition('Mls => *', slideToLeft()),
    transition('* => Mls', slideToRight()),
  ]);

function slideTo(direction: string) {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        top: 0,
        [direction]: 0,
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ [direction]: '-100%', opacity: 0 })
    ]),
    group([
      query(':leave', [
        animate('0.4s ease-in-out', style({ [direction]: '100%', opacity: 0 }))
      ], optional),
      query(':enter', [
        animate('0.4s ease-in-out', style({ [direction]: '0%', opacity: 1 }))
      ])
    ]),
    query(':leave', animateChild()),
    query(':enter', animateChild()),
  ]
}

function verticalSlideToTop() {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        transform: 'translateY(0%)',
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: 'translateY(100%)', opacity: 0 })
    ]),
    group([
      query(':leave', [
        animate('0.6s ease-in-out', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ], optional),
      query(':enter', [
        animate('0.6s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ])
    ]),
    query(':leave', animateChild()),
    query(':enter', animateChild()),
  ]
}

function verticalSlideToBottom() {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        transform: 'translateY(0%)',
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: 'translateY(-100%)', opacity: 0 })
    ]),
    group([
      query(':leave', [
        animate('0.6s ease-in-out', style({ transform: 'translateY(100%)', opacity: 0 }))
      ], optional),
      query(':enter', [
        animate('0.6s ease-in-out', style({ transform: 'translateY(0%)', opacity: 1 }))
      ])
    ]),
    query(':leave', animateChild()),
    query(':enter', animateChild()),
  ]
}

function slideToRight() {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        transform: 'translateX(0%)',
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: 'translateX(100%)', opacity: 0 })
    ], optional),
    group([
      query(':leave', [
        animate('0.4s ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
      ], optional),
      query(':enter', [
        animate('0.4s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ], optional)
    ]),
    query(':leave', animateChild(), optional),
    query(':enter', animateChild(),optional),
  ]
}

function slideToLeft() {
  const optional = { optional: true };
  return [
    query(':enter, :leave', [
      style({
        position: 'fixed',
        transform: 'translateX(0%)',
        width: '100%'
      })
    ], optional),
    query(':enter', [
      style({ transform: 'translateX(-100%)', opacity: 0 })
    ], optional),
    group([
      query(':leave', [
        animate('0.4s ease-in-out', style({ transform: 'translateX(100%)', opacity: 0 }))
      ], optional),
      query(':enter', [
        animate('0.4s ease-in-out', style({ transform: 'translateX(0%)', opacity: 1 }))
      ], optional)
    ]),
    query(':leave', animateChild(), optional),
    query(':enter', animateChild(), optional),
  ]
}

function slideToHome() {
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
      }),
    ]),
    group([
      query(':enter', [
        animate('800ms ease', keyframes([
          style({ transform: 'scale(0) translateX(100%)', offset: 0 }),
          style({ transform: 'scale(0.5) translateX(25%)', offset: 0.3 }),
          style({ transform: 'scale(1) translateX(0%)', offset: 1 }),
        ])),
      ]),
      query(':leave', [
        animate('800ms ease', keyframes([
          style({ transform: 'scale(1)', offset: 0 }),
          style({ transform: 'scale(0.5) translateX(-25%)', offset: 0.35 }),
          style({ opacity: 0, transform: 'translateX(-50%)', offset: 1 }),
        ])),
      ])
    ]),
  ]

}
  // trigger('routeAnimations', [
    // transition('* <=> *', [
    //   style({ position: 'relative' }),
    //   query(':enter, :leave', [
    //     style({
    //       position: 'absolute',
    //       top: 0,
    //       left: 0,
    //       width: '100%'
    //     })
    //   ], {optional: true}),
    //   query(':enter', [
    //     style({ left: '-100%' })
    //   ], {optional: true}),
    //   query(':leave', animateChild(), {optional: true}),
    //   group([
    //     query(':leave', [
    //       animate('400ms ease-out', style({ left: '100%' }))
    //     ], {optional: true}),
    //     query(':enter', [
    //       animate('400ms ease-out', style({ left: '0%' }))
    //     ], {optional: true}),
    //   ]),
    // ]),
    // transition('HomePage <=> Properties', [
    //   query(':enter, :leave', [
    //     style({
    //       position: 'absolute',
    //       left: 0,
    //       width: '100%',
    //     }),
    //   ]),
    //   group([
    //     query(':enter', [
    //       animate('400ms ease', keyframes([
    //         style({ transform: 'scale(0) translateX(100%)', offset: 0 }),
    //         style({ transform: 'scale(0.5) translateX(25%)', offset: 0.3 }),
    //         style({ transform: 'scale(1) translateX(0%)', offset: 1 }),
    //       ])),
    //     ]),
    //     query(':leave', [
    //       animate('400ms ease', keyframes([
    //         style({ transform: 'scale(1)', offset: 0 }),
    //         style({ transform: 'scale(0.5) translateX(-25%) rotate(0)', offset: 0.35 }),
    //         style({ opacity: 0, transform: 'translateX(-50%) rotate(-180deg) scale(6)', offset: 1 }),
    //       ])),
    //     ])
    //   ]),
    // ])
  // ]);
